<template>
  <div class="home" ref="home" id="home">
    <div class="home_section1">
      <div class="home_section1_container">
        <div class="home_section1_title">{{ this.$t("home.section1.title") }}</div>
        <div class="home_section1_subtitle">{{ this.$t("home.section1.subTitle") }}</div>
        <div class="home_section1_describe">{{ this.$t("home.section1.describe") }}</div>
        <div class="home_section1_btn" @click="contactUs()">
          {{ this.$t("home.section1.contactUs") }}
        </div>
      </div>
    </div>
    <div class="home_section2">
      <div class="home_section2_container">
        <div class="home_section2_title">{{ this.$t("home.section2.title") }}</div>
        <div class="home_section2_subtitle">{{ this.$t("home.section2.subTitle") }}</div>
        <div class="home_section2_info">
          <img class="info-img" src="@/assets/home/bg-home-section2.png" />
          <div class="info">
            <div class="info-count">
              <div class="info-count-item">
                <div class="num"  style="margin-left:36px;">{{ this.$t("home.section2.info1.num") }}</div>
                <div>{{ this.$t("home.section2.info1.name") }}</div>
                <div>{{ this.$t("home.section2.info1.type") }}</div>
              </div>
              <div class="info-count-item" style="margin-left:60px;">
                <div class="num">{{ this.$t("home.section2.info2.num") }}</div>
                <div>{{ this.$t("home.section2.info2.name") }}</div>
                <div>{{ this.$t("home.section2.info2.type") }}</div>
              </div>
              <div class="info-count-item" style="margin-left:50px;">
                <div class="num">
                  {{ this.$t("home.section2.info3.num") }}<span style="font-size:32px;">million</span>
                  <div class="num-plus">+</div>
                </div>
                <div>{{ this.$t("home.section2.info3.name") }}</div>
                <div>{{ this.$t("home.section2.info3.type") }}</div>
              </div>
            </div>
            <div class="info-decribe">
              <img class="info-decribe-img" src="@/assets/home/icon-jb.png" />
              <div class="info-decribe-content">{{ this.$t("home.section2.decribe1") }}</div>
            </div>
            <div class="info-decribe">
              <img class="info-decribe-img" src="@/assets/home/icon-aj.png" />
              <div class="info-decribe-content">{{ this.$t("home.section2.decribe2") }}</div>
            </div>
            <div class="info-decribe">
              <img class="info-decribe-img" src="@/assets/home/icon-jc.png" />
              <div class="info-decribe-content">{{ this.$t("home.section2.decribe3") }}</div>
            </div>
            <div class="home_section2_btns">
              <div class="home_section2_btn" @click="contactUs()">
                {{ this.$t("home.section1.contactUs") }}
              </div>
              <div class="home_section2_btn" @click="caseApply()">
                {{ this.$t("home.section1.caseApply") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块3 -->
    <div class="home_section3">
      <div class="home_section3_container">
        <div class="home_section3_title">{{ this.$t("home.section3.title") }}</div>
        <div class="home_section3_subtitle">{{ this.$t("home.section3.subTitle") }}</div>
        <div class="home_section3_info">
          <div class="info">
            <div class="info-decribe">
              <img class="info-decribe-img" src="@/assets/home/icon-peo.png" />
              <div class="info-decribe-content">{{ this.$t("home.section3.decribe1") }}</div>
            </div>
            <div class="info-decribe" style="margin-top:23px;">
              <img class="info-decribe-img" src="@/assets/home/icon-wallet.png" />
              <div class="info-decribe-content">{{ this.$t("home.section3.decribe2") }}</div>
            </div>
            <div class="info-decribe" style="margin-top:33px;">
              <img class="info-decribe-img" src="@/assets/home/icon-safe.png" />
              <div class="info-decribe-content">{{ this.$t("home.section3.decribe3") }}</div>
            </div>
            <div class="home_section3_tip">
              {{ this.$t("home.section3.tip1") }}:
              <img
                class="tip_img"
                src="@/assets/home/icon-home-black.png"
              />{{ this.$t("home.section3.tip2") }}
              <img class="tip_img" src="@/assets/home/icon-wallet-black.png" />{{
                this.$t("home.section3.tip3")
              }}
              <img class="tip_img" src="@/assets/home/icon-nft-black.png" /><span class="tip_ntf"
                >DeFi</span
              >
            </div>
            <div class="home_section3_btn" @click="contactUs()">
              {{ this.$t("home.section1.contactUs") }}
            </div>
          </div>
          <img class="info-img" src="@/assets/home/bg-home-section3.png" />
        </div>
      </div>
    </div>
    <!-- 模块4 -->
    <div class="home_section4">
      <div class="home_section4_container">
        <div class="home_section4_title">{{ this.$t("home.section4.title") }}</div>
        <div class="home_section4_subtitle">{{ this.$t("home.section4.subTitle") }}</div>
        <div class="home_section4_info">
          <img class="info-img" src="@/assets/home/bg-home-section4.png" />
          <div class="info">
            <div class="info-decribe" style="margin-top:39px;">
              <img class="info-decribe-img" src="@/assets/home/icon-gl.png" />
              <div class="info-decribe-content">{{ this.$t("home.section4.decribe1") }}</div>
            </div>
            <div class="info-decribe" style="margin-top:24px;">
              <img class="info-decribe-img" src="@/assets/home/icon-gl.png" />
              <div class="info-decribe-content">{{ this.$t("home.section4.decribe2") }}</div>
            </div>
            <div class="info-decribe" style="margin-top:30px;">
              <img class="info-decribe-img" src="@/assets/home/icon-tip.png" />
              <div class="info-decribe-content">{{ this.$t("home.section4.decribe3") }}</div>
            </div>
            <div class="info-decribe" style="margin-top:23px;">
              <img class="info-decribe-img" src="@/assets/home/icon-money.png" />
              <div class="info-decribe-content">{{ this.$t("home.section4.decribe4") }}</div>
            </div>
            <div class="home_section4_tip">{{ this.$t("home.section4.tip") }}</div>
            <div class="home_section4_btn" @click="goTrack()">
              {{ this.$t("home.section4.goTrack") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块5 -->
    <div class="home_section5">
      <div class="home_section5_container">
        <div class="home_section5_title">{{ this.$t("home.section5.title") }}</div>
        <div class="info-decribe">
          <div class="info-decribe-item">
            <img class="item-img" src="@/assets/home/bg-home-section5-1.png" />
            <div class="item-content" v-html="$t('home.section5.decribe1')"></div>
          </div>
          <div class="info-decribe-item">
            <img class="item-img" src="@/assets/home/bg-home-section5-2.png" />
            <div class="item-content" v-html="$t('home.section5.decribe2')"></div>
          </div>
          <div class="info-decribe-item">
            <img class="item-img" src="@/assets/home/bg-home-section5-3.png" />
            <div class="item-content" v-html="$t('home.section5.decribe3')"></div>
          </div>
          <div class="info-decribe-item">
            <img class="item-img" src="@/assets/home/bg-home-section5-4.png" />
            <div class="item-content" v-html="$t('home.section5.decribe4')"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块6 -->
    <div class="home_section6">
      <div class="home_section6_title">{{ this.$t("home.section1.contactUs") }}</div>
      <div class="home_section6_code">
        <div class="code-item">
          <img class="code-item-img" src="@/assets/home/icon-code-wx.png" />
          <div class="code-item-contact">
            <div class="contact-item">
              <div class="contact-item-title">
                <img class="contact-item-img" src="@/assets/home/icon-wx.png" /> We Chat
              </div>
              <div class="contact-item-content">
                青岛比特丛林科技有限公司
              </div>
            </div>
          </div>
        </div>

        <div class="code-item">
          <img class="code-item-img" src="@/assets/home/icon-code-wb.png" />
          <div class="code-item-contact">
            <div class="contact-item">
              
              <div class="contact-item-title">
              <img class="contact-item-img" src="@/assets/home/icon-wb.png" />Weibo
              </div>
              <div class="contact-item-content">
                
                青岛比特丛林科技有限公司
              </div>
            </div>
          </div>
        </div>
        
        <div class="code-item">
          <img class="code-item-img" src="@/assets/home/icon-code-twitter.png" />
          <div class="code-item-contact">
            <div class="contact-item">
              <div class="contact-item-title">
              <img class="contact-item-img" src="@/assets/home/icon-twitter.png" />Twitter
              </div>
              <div class="contact-item-content">
                
                @Bitjungle_team
              </div>
            </div>
          </div>
        </div>
        <!-- <img class="code-item-img" src="@/assets/home/icon-code-wb.png"  /> -->
      </div>
      <div class="home_section6_mail">
        <div class="mail-item">
          <img class="mail-img" src="@/assets/home/icon-mail.png" />
          <div class="mail-content">Mail：contact@bitjungle.io</div>
        </div>
      </div>
      <div class="home_section6_tip">@2018-2022 bitjungle.io ALL Rights Reserved</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  mounted() {},
  methods: {
    contactUs() {
      // var container = this.$refs.home;
      // container.scrollTop = container.scrollHeight;
      this.$emit("scrollToBottom");
    },
    caseApply(){
      let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
      if(isLogin == 0){
        this.$emit("showLoginDia")
        return
      }
      this.$router.push("caseApply");
    },
    goTrack() {
      let isLogin = localStorage.getItem("isLogin")?Number(localStorage.getItem("isLogin")):0
      if(isLogin == 0){
        this.$emit("showLoginDia")
        return
      }
      this.$router.push("relationChart");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  box-sizing: border-box;
  flex: 1;
  font-family: PingFangSC-Semibold;
  .home_section1 {
    height: 648px;
    display: flex;
    justify-content: center;
    background-image: url("../../assets/home/bg-home-section1.png");
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .home_section1_container {
      width: 1280px;
      height: 675px;
      display: flex;
      flex-direction: column;
    }
    .home_section1_title {
      height: 78px;
      margin-top: 200px;
      margin-left: 41px;
      font-size: 56px;
      font-weight: 600;
      color: #ffffff;
      line-height: 78px;
    }

    .home_section1_subtitle {
      font-size: 24px;
      color: #ffffff;
      margin-left: 41px;
    }

    .home_section1_describe {
      font-size: 23px;
      color: #a7a7a7;
      margin-left: 41px;
      margin-top: 5px;
      width: 530px;
    }

    .home_section1_btn {
      margin-left: 41px;
      width: 198px;
      height: 51px;
      background: linear-gradient(360deg, #3c91ff 0%, #3b65fd 100%);
      border-radius: 26px;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 51px;
      text-align: center;
      margin-top: 106px;
      cursor: pointer;
    }
  }

  .home_section2 {
    display: flex;
    background-color: #fff;
    height: 800px;
    justify-content: center;
    .home_section2_container {
      width: 1280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
    }

    .home_section2_title {
      height: 67px;
      font-size: 45px;

      font-weight: 600;
      color: rgba(32, 32, 56, 0.85);
      line-height: 67px;
      margin-top: 73px;
      margin-left: 41px;
    }

    .home_section2_subtitle {
      height: 39px;
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: rgba(71, 65, 89, 0.85);
      line-height: 39px;
      margin-left: 41px;
    }

    .home_section2_info {
      display: flex;
      width: 100%;
      margin-top: 36px;

      .info-img {
        margin-left: 25px;
        height: 529px;
        width: 529px;
      }

      .info {
        flex: 1;
        // margin-right: 41px;
        margin-left: 30px;
        .info-count {
          display: flex;
          // justify-content: space-evenly;
          margin-top: 50px;
          .info-count-item {
            font-size: 19px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: rgba(32, 32, 56, 0.85);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            .num {
              height: 66px;
              font-size: 56px;
              font-family: DINAlternate-Bold;
              font-weight: bold;
              color: rgba(32, 32, 56, 0.85);
              line-height: 66px;
              margin-bottom: 21px;
              position: relative;
            }

            .num-plus {
              position: absolute;
              right: -10px;
              top: -20px;
              font-size: 32px;
            }
          }
        }

        .info-decribe {
          display: flex;
          min-height: 37px;
          margin-top: 27px;

          .info-decribe-img {
            height: 37px;
            width: 37px;
          }

          .info-decribe-content {
            // height: 37px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: rgba(32, 32, 56, 0.85);
            line-height: 34px;
            margin-left: 18px;
          }
        }

        .home_section2_btn {
          width: 198px;
          height: 51px;
          border-radius: 26px;
          border: 1px solid #3955f0;
          font-size: 19px;

          font-weight: 600;
          color: #2e43eb;
          line-height: 51px;
          text-align: center;
          margin-top: 47px;
          cursor: pointer;
          margin-right: 20px;
        }
        .home_section2_btns{
          display: flex;
        }
      }
    }
  }

  .home_section3 {
    display: flex;
    background-color: #f9f9f9;
    height: 800px;
    justify-content: center;
    .home_section3_container {
      width: 1280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f9f9f9;
    }

    .home_section3_title {
      height: 67px;
      font-size: 48px;
      font-weight: 600;
      color: rgba(32, 32, 56, 0.85);
      line-height: 67px;
      margin-top: 68px;
      margin-left: 41px;
    }

    .home_section3_subtitle {
      width: 772px;
      height: 34px;
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: rgba(71, 65, 89, 0.85);
      line-height: 34px;
      margin-left: 41px;
      margin-top: 10px;
    }

    .home_section3_info {
      display: flex;
      margin-left: 41px;

      .info-img {
        height: 529px;
        width: 528px;
        margin-left: 129px;
        margin-top: 30px;
      }

      .info {
        margin-top: 129px;

        .info-decribe {
          display: flex;
          height: 37px;

          .info-decribe-img {
            height: 37px;
            width: 37px;
          }

          .info-decribe-content {
            height: 37px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: rgba(32, 32, 56, 0.85);
            line-height: 37px;
            margin-left: 18px;
          }
        }

        .home_section3_tip {
          height: 26px;
          font-size: 22px;
          font-weight: 600;
          color: rgba(32, 32, 56, 0.85);
          line-height: 26px;
          margin-top: 106px;
          display: flex;
          align-items: center;

          .tip_img {
            width: 26px;
            height: 26px;
            margin-left: 10px;
            margin-right: 6px;
          }

          .tip_ntf {
            background-color: #5060df;
            color: #fff;
            font-size: 20px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            width: 47px;
          }
        }

        .home_section3_btn {
          width: 198px;
          height: 51px;
          border-radius: 26px;
          border: 1px solid #3955f0;
          font-size: 19px;

          font-weight: 600;
          color: #2e43eb;
          line-height: 51px;
          text-align: center;
          margin-top: 53px;
          cursor: pointer;
        }
      }
    }
  }

  .home_section4 {
    display: flex;
    background-color: #fff;
    height: 808px;
    justify-content: center;
    .home_section4_container {
      width: 1280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
    }
    .home_section4_title {
      height: 67px;
      font-size: 48px;
      font-weight: 600;
      color: rgba(32, 32, 56, 0.85);
      line-height: 67px;
      margin-top: 73px;
      margin-left: 41px;
    }

    .home_section4_subtitle {
      // width: 665px;
      height: 66px;
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: rgba(71, 65, 89, 0.85);
      line-height: 33px;
      margin-left: 41px;
      margin-top: 9px;
    }

    .home_section4_info {
      display: flex;
      width: 100%;
      margin-top: 26px;
      .info-img {
        margin-left: 41px;
        height: 529px;
        width: 529px;
      }

      .info {
        flex: 1;
        margin-left: 76px;

        .info-decribe {
          display: flex;
          height: 37px;
          margin-top: 23px;

          .info-decribe-img {
            height: 37px;
            width: 37px;
          }

          .info-decribe-content {
            height: 37px;
            font-size: 24px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: rgba(32, 32, 56, 0.85);
            line-height: 37px;
            margin-left: 18px;
          }
        }

        .home_section4_tip {
          height: 34px;
          font-size: 24px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          color: rgba(32, 32, 56, 0.85);
          line-height: 34px;
          margin-top: 81px;
          margin-bottom: 48px;
        }

        .home_section4_btn {
          width: 198px;
          height: 51px;
          border-radius: 26px;
          border: 1px solid #3955f0;
          font-size: 19px;
          font-weight: 600;
          color: #2e43eb;
          line-height: 51px;
          text-align: center;
          margin-top: 51px;
          cursor: pointer;
        }
      }
    }
  }

  .home_section5 {
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    height: 720px;
    .home_section5_container {
      width: 1280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f9f9f9;
      padding: 0 41px;
      box-sizing: border-box;
    }

    .home_section5_title {
      height: 48px;
      font-size: 48px;
      font-weight: 600;
      color: rgba(32, 32, 56, 0.85);
      line-height: 48px;
      margin-top: 67px;
    }

    .info-decribe {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      // height: 496px;
      .info-decribe-item {
      display: flex;
      flex-direction: column;
        .item-img {
          width: 288px;
          height: 192px;
        }

        .item-content {
          width: 288px;
          height: 320px;
          background-color: #fff;
          font-size: 16px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: rgba(37, 37, 40, 0.68);
          text-shadow: 0px 2px 10px rgba(210, 210, 210, 0.5);
          box-shadow: 0px 2px 10px 0px rgba(210, 210, 210, 0.5);
          border-radius: 1px;
          padding: 12px 18px;
          box-sizing: border-box;
        }
      }
    }
  }

  .home_section6 {
    height: 480px;
    background: #00061e;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 240px;

    .home_section6_title {
      height: 32px;
      font-size: 30px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 32px;
      margin-top: 56px;
    }

    .home_section6_code {
      display: flex;
      width: 600px;
      justify-content: space-between;
      margin-top: 44px;

      .code-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        font-size: 14px;

        .code-item-img {
          width: 120px;
          height: 120px;
        }

        .code-item-contact {
          display: flex;
          margin-top: 23px;
          justify-content: center;

          .contact-item {

            .contact-item-title{
              height: 23px;
              line-height: 23px;
              font-size: 14px;
              color: rgba(135, 135, 135, 0.68);
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .contact-item-img {
              width: 23px;
              height: 23px;
              margin-right: 8px;
            }

            .contact-item-content {
              height: 40px;
              line-height: 20px;
              color: rgba(135, 135, 135, 0.68);
              // margin-left: 8px;
            }
          }
        }
      }
    }

    .home_section6_mail {
      font-size: 14px;

      font-weight: 600;
      color: rgba(135, 135, 135, 0.68);
      height: 23px;
      line-height: 23px;
      display: flex;
      width: 600px;
      justify-content: center;
      margin-top: 23px;
      .mail-item {
        display: flex;
      }

      .mail-img {
        height: 23px;
        width: 23px;
      }

      .mail-content {
        height: 23px;
        line-height: 23px;
        margin-left: 8px;
      }
    }

    .home_section6_tip {
      height: 27px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(135, 135, 135, 0.68);
      line-height: 27px;
      margin-top: 72px;
    }
  }
}
</style>
